import React, { useMemo } from 'react';
import { TemplateInfo } from '../api';
import ResponseCard from './response-card';
import { Table, Typography } from '@alifd/next';

function TemplateVariable(props: {
  data: TemplateInfo['scenes'][number]['variables'][number];
}) {
  const { data } = props;
  return (
    <div>
      <div>
        variable: {data.name}, type: {data.type}
      </div>
      <div style={{ marginLeft: 12 }}>
        <Table dataSource={data.properties}>
          <Table.Column
            title="property name"
            width={240}
            dataIndex="name"
            cell={(value) => {
              return `${data.name}.${value}`;
            }}
          />
          <Table.Column title="default value" dataIndex="default" />
        </Table>
      </div>
    </div>
  );
}
function TemplateScene(props: { data: TemplateInfo['scenes'][number] }) {
  return (
    <div>
      {props.data?.variables.map((item, index) => {
        return <TemplateVariable data={item} key={index} />;
      })}
    </div>
  );
}

type PropertyDataItem =
  TemplateInfo['scenes'][number]['variables'][number]['properties'][number] & {
    type: TemplateInfo['scenes'][number]['variables'][number]['type'];
    variableName: TemplateInfo['scenes'][number]['variables'][number]['name'];
    sceneIndex: number;
  };

function getPropertyData(data: TemplateInfo): PropertyDataItem[] {
  const out: PropertyDataItem[] = [];

  data.scenes.forEach((scene, sceneIndex) => {
    scene.variables.forEach((variable) => {
      variable.properties.forEach((property) => {
        out.push({
          ...property,
          type: variable.type,
          variableName: variable.name,
          sceneIndex,
        });
      });
    });
  });

  return out;
}

function TemplateProperties(props: { data: PropertyDataItem[] }) {
  return (
    <Table dataSource={props.data}>
      <Table.Column
        title="property name"
        width={240}
        dataIndex="name"
        cell={(value, index, record: PropertyDataItem) => {
          return `${record.variableName}.${value}`;
        }}
      />
      <Table.Column title="default value" dataIndex="default" />
    </Table>
  );
}

function TemplateResultLegacy(props: { data: TemplateInfo | undefined }) {
  if (!props.data) return null;
  return (
    <ResponseCard>
      {props.data?.scenes.map((item, index) => {
        return (
          <div>
            <Typography.H6 style={{ margin: 0 }}>
              Scene: {index + 1}
            </Typography.H6>
            <TemplateScene data={item} key={index} />
          </div>
        );
      })}
    </ResponseCard>
  );
}

function TemplateResult(props: { data: TemplateInfo | undefined }) {
  const properties = useMemo(
    () => props.data && getPropertyData(props.data),
    [props.data],
  );
  if (!properties) return null;
  return (
    <ResponseCard>
      <TemplateProperties data={properties} />
    </ResponseCard>
  );
}

export default TemplateResult;
