import axios from 'axios';

type APIResponse<T> = {
  code: number;
  data: T;
  message: string;
}


interface Scene {
  variables: Variable[];
}

interface Variable {
  name: string;
  properties: Property[];
  type: string;
}

interface Property {
  default: string;
  name: string;
}

export interface TemplateInfo {
  scenes: Scene[];
  template_id: string;
  video_id: string;
}

export type TemplateInfoResponse = APIResponse<TemplateInfo>

export function getTemplate(params:{
  apiKey: string;
  videoId: string;
}) {
  return axios.get<APIResponse<TemplateInfo>>(
    'https://api.heygen.com/v1/template.get',
    {
      params: { video_id: params.videoId },
      headers: { 'x-api-key': params.apiKey },
    },
  );
}
