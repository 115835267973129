import { Divider } from '@alifd/next';
import React, { PropsWithChildren } from 'react';

function ResponseCard(props: PropsWithChildren) {
  return (
    <div
      style={{
        border: '1 solid #eee',
        borderRadius: 6,
        background: '#f0f0f0',
      }}
    >
      <div style={{ padding: 4 }}>RESPONSE</div>
      <Divider style={{ margin: 0 }} />
      <div style={{ padding: 8 }}>{props.children}</div>
    </div>
  );
}

export default ResponseCard;
