import * as core from "./core";
import pacificLogger from "./logger/pacific";

const globalParams: Record<string, any> = {};

const addGlobalParamsHook = core.createHook((name, params) => {
  return {
    params: { ...globalParams, ...params },
  };
});

core.addHook("before", addGlobalParamsHook);

core.addLogger(pacificLogger);

export const logEvent = core.logEvent;