import axios from "axios";
import { createLogger } from "../core";

const endpoint = "https://api2.heygen.com//v1/movio.track";

const pacificLogger = createLogger((name, params) => {
  const { ...rest } = params || {};
  const formData = new FormData();
  formData.append("origin", window.location.origin);
  formData.append("event_name", name);
  formData.append("client_ts", `${new Date().getTime()}`);
  formData.append("page_name", window.location.pathname);
  formData.append("params", JSON.stringify(rest));
  axios.post(endpoint, formData);
});

export default pacificLogger;
