type Params = Record<string, any>;
type Logger = (name: string, params: Params) => void;
type HookResult = {
  params?: Params;
  state?: "abort";
};
type Hook = (name: string, params?: Params) => HookResult | Promise<HookResult>;

type HookStage = "before" | "after";

const loggers: Logger[] = [];

const hooks: Partial<Record<string, Hook[]>> = {};

function getHooks(stage: HookStage): Hook[] {
  hooks[stage] = hooks[stage] || [];
  return hooks[stage]!;
}

export function addHook(stage: HookStage, hook: Hook) {
  const list = getHooks(stage);
  list.push(hook);
}

export function addLogger(logger: Logger) {
  loggers.push(logger);
}

export function createHook(hook: Hook) {
  return hook;
}
export function createLogger(logger: Logger) {
  return logger;
}

export async function logEvent(name: string, params?: Params): Promise<void> {
  const beforeHooks = getHooks("before");
  let curParams = params;
  for (const hook of beforeHooks) {
    try {
      const res = await hook(name, curParams);
      if (res.state === "abort") return;
      curParams = res.params || curParams;
    } catch (e) {}
  }

  loggers.forEach((logger) => {
    logger(name, curParams || {});
  });
}
