import React, { useState } from "react";
import { Box, Typography, Input, Divider, Button, Message } from "@alifd/next";
import { useMemoizedFn, useMount, useReactive } from "ahooks";
import { getTemplate } from "../../api";
import { AxiosError } from "axios";
import TemplateResult from "../../components/template-result";
import { logEvent } from "../../utils/analyze";

function Main() {
  useMount(() => {
    logEvent("PAGE_VIEW");
  });

  const [apiKey, setApiKey] = useState("");

  const getTemplateState = useReactive<{
    loading?: boolean;
    data?: any;
    videoId?: string;
  }>({});

  const handleGetTemplate = useMemoizedFn(async () => {
    logEvent("API_STU_CLK_GET_TMP", {
      video_id: getTemplateState.videoId,
      api_key: apiKey,
    });
    if (!getTemplateState.videoId) return;
    try {
      getTemplateState.data = undefined;
      getTemplateState.loading = true;
      const res = await getTemplate({
        videoId: getTemplateState.videoId,
        apiKey,
      });
      getTemplateState.data = res.data.data;
    } catch (e) {
      const err = e as AxiosError;
      Message.error((err.response?.data as any).message || err.message);
    } finally {
      getTemplateState.loading = false;
    }
  });

  return (
    <div>
      <Typography.H1 style={{ marginTop: 0 }}>HeyGen API Studio</Typography.H1>
      <Box direction="row" spacing={12} align="baseline">
        <Typography.H5>AUTHORIZATION</Typography.H5>
        <Typography.Text>Input your APIKey</Typography.Text>
      </Box>
      <Input
        addonTextBefore="APIKey"
        style={{ maxWidth: 500 }}
        value={apiKey}
        onChange={setApiKey}
      />
      <Divider />
      <Typography.H4>Get Template</Typography.H4>
      <Box direction="row" spacing={12} align="baseline">
        <Typography.H5>Video Id</Typography.H5>
        <Typography.Text>Input your Video Id</Typography.Text>
      </Box>
      <Box spacing={12} align="flex-start">
        <Input
          addonTextBefore="VideoId"
          style={{ maxWidth: 500 }}
          value={getTemplateState.videoId}
          onChange={(v) => {
            getTemplateState.videoId = v;
          }}
        />
        <Button
          type="primary"
          onClick={handleGetTemplate}
          loading={getTemplateState.loading}
        >
          Run
        </Button>
        <TemplateResult data={getTemplateState.data} />
      </Box>
    </div>
  );
}

export default Main;
