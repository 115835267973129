import Main from '../pages/main';
import BaseLayout from './layout/base';

function Routes() {
  return (
    <BaseLayout>
      <Main />
    </BaseLayout>
  );
}

export default Routes;
